import React, { useState, useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useCookie } from 'hooks/useCookies';
import { useHTTP } from 'hooks/useRequest';
import { addressFormatter, isEmptyObject, screenSize } from 'helpers/utils';
import useBasketStore from 'stores/basketStore/useBasketStore';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckoutLock,
} from 'components/shared/CommonSvgs';
import AmountRow from './AmountRow';

const OrderSummary = ({ isBasketPage, checkoutPath, disableCheckout }) => {
  const {
    basket,
    basket: {
      delivery_address1,
      delivery_address2,
      delivery_address3,
      delivery_address4,
      delivery_address5,
      delivery_postcode,
      goods_total: currentBasketGoods,
      lines: currentBasketLines,
      delivery_charge: currentBasketDeliveryCharge,
      bulk_discount: currentBasketBulkDiscount,
      vat,
      total,
    },
    setCurrentStep,
    setCurrentBasket,
  } = useBasketStore(state => ({
    basket: state.basket,
    setCurrentStep: state.setCurrentStep,
    setCurrentBasket: state.setCurrentBasket,
  }));

  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
  });
  const isOpenInitialState = !(screenSize() < 768);

  const [isOpen, setIsOpen] = useState(
    isBasketPage ? true : isOpenInitialState,
  );
  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedCookieValue = JSON.parse(pricingCookieValue);
  const showPrices = (parsedCookieValue && isBasketPage) || !isBasketPage;

  useEffect(() => {
    if (delivery_address1 !== '') {
      const basketDeliveryAddress = {
        delivery_address1,
        delivery_address2,
        delivery_address3,
        delivery_address4,
        delivery_address5,
        delivery_postcode,
      };

      setDeliveryAddress(basketDeliveryAddress);
    }
  }, [basket]);

  const { data } = useHTTP(requestConfig);

  const handleEdit = () => {
    setCurrentStep('delivery_address');
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: { basket: { step: 'delivery_address' } },
    });
  };

  useEffect(() => {
    if (data) {
      const { basket: updatedBasket } = data;
      setCurrentBasket(updatedBasket);
      setCurrentStep(updatedBasket.step.toUpperCase());
      setRequestConfig({
        url: null,
        method: null,
      });
    }
  }, [data]);

  return (
    <div className="order-summary col-12 m-col-3 br-8 fs-14 ds m-pos-sticky m-pos-top-16">
      <div
        className="order-summary__header row grid-no-gap bb b-colour--grey
        mb-16 pb-14 pt-14 pl-16 pr-16">
        <h3 className="col-11 m-col-8 m-0">Order Summary</h3>
        {!isBasketPage && (
          <button
            className="order-summary__show col-1"
            type="button"
            aria-label={isOpen ? 'Hide' : 'Show'}
            onClick={() => setIsOpen(currState => !currState)}>
            {isOpen ? (
              <ArrowUpIcon className="order-summary__show-icon" />
            ) : (
              <ArrowDownIcon className="order-summary__show-icon" />
            )}
          </button>
        )}
      </div>
      {isOpen && (
        <div className="order-summary__content">
          {!isBasketPage && (
            <div className="order-summary__item-images pl-16 pr-16 row pb-14 bb b-colour--grey">
              {currentBasketLines.map(
                ({
                  product: {
                    id,
                    description_short: descriptionShort,
                    image_url: imageUrl,
                  },
                }) => (
                  <div className="order-summary__item col-2" key={id}>
                    <img
                      src={imageUrl}
                      alt={descriptionShort}
                      width="40"
                      height="40"
                    />
                  </div>
                ),
              )}
            </div>
          )}
          {!isBasketPage && !isEmptyObject(deliveryAddress) && (
            <div
              className="order-summary__delivery-address pl-16 pr-16 
            row pb-14 bb b-colour--grey">
              <div className="col-12">
                <div className="fl ai-c jc-sb">
                  <p className="fs-14 fw-700 mb-12">Delivery Address</p>
                  <Button
                    className="bg-none td-u"
                    handleClick={() => handleEdit()}>
                    Edit
                  </Button>
                </div>
                <p className="fs-14 m-0">{addressFormatter(deliveryAddress)}</p>
              </div>
            </div>
          )}
          {showPrices && (
            <div className="order-summary__amounts">
              {currentBasketGoods && (
                <AmountRow label="Goods Total" amount={currentBasketGoods} />
              )}
              {vat && <AmountRow label="VAT" amount={vat} />}
              {currentBasketBulkDiscount && (
                <AmountRow
                  label="Bulk Discount"
                  amount={currentBasketBulkDiscount}
                />
              )}
              {currentBasketDeliveryCharge && (
                <AmountRow
                  label="Delivery"
                  amount={currentBasketDeliveryCharge}
                />
              )}
              {total && <AmountRow label="Total" amount={total} isBold />}
            </div>
          )}

          {isBasketPage && (
            <div className="col-12 fl jc-c pl-16 pr-16">
              <a
                href={checkoutPath}
                aria-label="Continue to checkout"
                className={`btn btn-orange mtb-16 width-100 fw-700 br-4
                ${disableCheckout ? 'disabled' : ''}`}>
                <div className="mr-5">
                  <CheckoutLock />
                </div>
                <span>Continue to Checkout</span>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
