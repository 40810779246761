import React from 'react';
import { SplideSlide } from '@splidejs/react-splide';
import { useCookie } from 'hooks/useCookies';

import { SaleIcon } from 'components/shared/CommonSvgs';

const SliderProduct = ({
  item: {
    id,
    description,
    imgUrl,
    url,
    brandName,
    brandUrl,
    salePrice,
    oldPrice,
  },
}) => {
  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedCookieValue = JSON.parse(pricingCookieValue);
  return (
    <SplideSlide
      className={`splide__slide
              splide__slide--${id} box-shadow-on-hover br-8`}
      key={id}
      data-testid="splide__slide--item">
      {salePrice && (
        <div className="splide__slide--sale-icon">
          <SaleIcon />
        </div>
      )}
      <div
        className={`plr-32 
      ${salePrice ? 'pt-16' : 'ptb-8 homepage-product-container__image-wrapper'} 
      fl ai-c jc-c`}>
        <a href={url} target="_blank" rel="noreferrer">
          <img
            src={imgUrl}
            className={`${salePrice ? 'sale-price__image' : ''}`}
            width={salePrice ? '160' : '230'}
            height={salePrice ? '160' : '230'}
            alt={description || ''}
          />
        </a>
      </div>
      <div className="homepage-product-container__content p-16">
        <p className="homepage-product-container__brand-name fs-14 fw-400 mb-5 mt-0">
          {brandName && (
            <a href={brandUrl} target="_blank" rel="noreferrer">
              {brandName}
            </a>
          )}
        </p>

        {description && (
          <p className={`fw-700 fs-18 mt-0 ${salePrice ? 'mb-0' : ''}`}>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className={salePrice ? 'sale-price__description' : ''}>
              {description}
            </a>
          </p>
        )}
        {salePrice && parsedCookieValue && (
          <p className="fs-18 mt-0 mb-0">
            <a href={url} target="_blank" rel="noreferrer">
              <span className="fs-24 fw-700 c--red">£{salePrice}</span> was{' '}
              <span className="td-lt c--grey70">£{oldPrice}</span>
            </a>
          </p>
        )}
      </div>
    </SplideSlide>
  );
};

export default SliderProduct;
